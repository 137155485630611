/*@import url(https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap);*/
@import url(https://fonts.googleapis.com/css2?family=Rubik);

$body-color: #999;

$font-family-base: "Rubik:wght@300", monospace;

@import "node_modules/bootstrap/scss/bootstrap";

body {
    background-image: url('../images/assorted-title-cassette-tapes-2796145.jpg');
    background-size: cover;
}

.backdrop {
    position: fixed;
    top:0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
}

a.active {
    font-weight: bold;
}

.navbar {
    background: linear-gradient(to bottom, transparent, black),
    url(../images/selective-focus-photo-of-fretboard-1808343-cropped-rotated.jpg);
    background-repeat: none;
    background-size: cover;
    background-position: bottom;
    vertical-align: top;
    padding-bottom: 5rem;
}

.hat-img {
    height: 12rem;
    float: right;
    padding-left: 2rem;
}